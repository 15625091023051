.img-hover-zoom--colorizes img {
  transition: transform .3s, filter 0.5s ease-in-out;
  filter: grayscale(100%);
}

/* The Transformation */
.img-hover-zoom--colorizes:hover img {
  filter: grayscale(0);
  transform: scale(1.1);
}


.img-hover-zoom--colorize img {
  transition: transform .3s, filter 0.5s ease-in-out;
 
}

/* The Transformation */
.img-hover-zoom--colorize:hover img {
  
  transform: scale(1.1);
}



.img-hover-zoom--brightness img {
  transition: transform 2s, filter 1.0s ease-in-out;
  transform-origin: center center;
  filter: brightness(30%);
}

/* The Transformation */
.img-hover-zoom--brightness:hover img {
  filter: brightness(100%);
  transform: scale(1.2);
}
/* Quick-zoom Container */
.img-hover-zoom--quick-zoom img {
  transform-origin: 0 0;
  transition: transform .25s, visibility .25s ease-in;
}

/* The Transformation */
.img-hover-zoom--quick-zoom:hover img {
  transform: scale(2);
}

.img-hover-zoom {
  
  overflow: hidden; /* Removing this will break the effects */
}

