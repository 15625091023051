.main-footer{
		color: #e95721;
		position: relative;
		bottom: -50px;
		width: 100%;
}

.page-container{
	display: flex;
	flex-direction: column;
	min-height: 100;
}

