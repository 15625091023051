* {
	
	box-sizing: border-box;
	margin: 0;
	padding: 0; 

}

.App{
	
	
	display: flex;
}

img{

display: block;
cursor: pointer;
}

.container{
	margin: auto;
	
	
}
.container .selected {
		width: 1200px;
		height:600px;
		margin: 0 auto;
		border: 4px solid #fcbc3c;
}

.container .imgContainer {
	
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	
}

.container .imgContainer img{
		width: 247px;
		height:190px;
		margin: 5px 0;
		border: 4px solid #fcbc3c;
}